import moment from 'moment'

export default function(detail, $t) {
  this.id = detail.id
  this.storeParameter = detail.storeParameter || null
  this.showPopup = false
  this.storeName = detail.storeName
  this.dismissReason = detail.dismissReason
  this.message = detail.errorCode ? $t(`ApiErrorCode.${detail.errorCode}`) : (detail.message
    ? $t('ScheduledJob.Schedule_Worked_Do')
    : detail.scheduleTypeName === 'TransferRequest' || detail.scheduleTypeName === 'OrderRequest'
      ? null
      : $t('ScheduledJob.Schedule_Worked_Do_Nothing'))
  this.executionDate = detail.executionDate ? moment(detail.executionDate).format('YYYY-MM-DD') : null
  this.situation = $t(`ScheduledJob.Col_schedule_Status_${detail.status}`)
  this.operation = detail.scheduleTypeName === 'TransferRequest' || detail.scheduleTypeName === 'OrderRequest'
    ? $t(`ScheduledJob.Col_scheduleType_${detail.scheduleTypeName}`)
    : $t(`ScheduledJob.Col_scheduleType_${detail.jobTypeName}`)
}
