import ScheduledJob from '../'
import ScheduledJobForm from '@/view/pages/ScheduledJob/popup/form.vue'
import ScheduledDetail from '@/view/pages/ScheduledJob/detail.vue'

export default [
  {
    name: 'ScheduledJob',
    path: '/scheduledjob',
    component: ScheduledJob,
    meta: {
      main_menu: true,
      slug: 'scheduledjob',
      page: 'scheduledjob',
      category: 'Governance',
      permissionKey: 'ScheduledJobsMenu'
    },
    children: [
      {
        path: 'new',
        name: 'newScheduledJob',
        component: ScheduledJobForm
      }
    ]
  },
  {
    name: 'ScheduledJobDetail',
    path: '/scheduledjob/detail/:id',
    component: ScheduledDetail,
    meta: {
      slug: 'scheduledjob',
      page: 'scheduledjob',
      category: 'Governance',
      permissionKey: 'ScheduledJobsMenu'
    },
    children: []
  }
]
