<template lang="pug">
Popup

    template(slot="title") {{ $t('ScheduledJob.detail_Popup_Title_Inventory') }}

    template(slot="content")

      // <<< modal
      Modal.ListModal(
        :isOpen="showModal",
        @closeModal="changeShowModal(false)"
        )
        template(slot="title")
          | {{ $t('ScheduledJob.StoreList') }}
        template(slot="content")
           form.Modal-Listitems(
            data-vv-scope="modal"
            id="modal-schedule-store-modal",
            @submit.prevent="submitModal"
            )
            .Search
              .icon-field
                Icon.icon-search(name="icon-search")
              input.txt(
                v-model="search",
                id="input-schedule-store-search-in-list"
                @input="searchOnStoreList(search)"
                :placeholder="$t('ScheduledJob.detail_Store_Search')")
            .row.header
              .col
                CustomCheckbox(
                  :checked="storeListLength === selectedStores.length"
                  :disabled="search !== ''"
                  :label="$t('ScheduledJob.detail_Popup_FormField_StoreName')",
                  @change="toggleAll")

              .col {{ $t('ScheduledJob.detail_Popup_FormField_Parameter') }}
              .col {{ $t('ScheduledJob.detail_Popup_FormField_Parameter_2') }}
            .row(
              v-for="(item, index) in selectOptionsStoreList",
              :key="`${item.id}-${item.name}`"
              v-if="item.isSearched")
              .col
                CustomCheckbox(
                  :label="item.name",
                  v-model="item.isChecked")
              .col
                input.txt.parameter.ff-mono.no-spinners(
                  :name="`parameter1-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`modal.parameter1-${index}`) }"
                  v-model="item.parameter1"
                  v-validate="item.isChecked && !item.parameter2 ? 'required|integer|min_value:1' : ''"
                  autocomplete="off"
                  type="number"
                )
              .col
                input.txt.parameter.ff-mono.no-spinners(
                  :name="`parameter2-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`modal.parameter2-${index}`) }"
                  v-validate="item.isChecked && !item.parameter1 ? 'required|integer|between:0,23' : ''"
                  v-model="item.parameter2"
                  autocomplete="off"
                  type="number"
                )

        template(slot="footer")
          .modalFooter
            Button.uppercase.addButton(
            primary
            type="submit"
            :disabled="selectedStores.length === 0"
            id="button-modal-schedule-store-modal"
            form="modal-schedule-store-modal")
              span {{ $t('ScheduledJob.detail_Popup_FormField_Add') }}
      // Modal finish //

      form.Form#form-scheduled-job(@submit.prevent="onSubmitForm")
        label.Form-item-label {{ $t('ScheduledJob.popupForm_Field_ScheduleType') }}
        .Form-item#schedule-form-item-select-inventory-type
          .control.form-item-select
            Icon.icon-down-arrow(name="icon-down-arrow")
            select.select(
              id="select-schedule-types"
              @change="resetForm"
              v-model="form.scheduleTypeName"
            )
              option(
                v-for="types in scheduleTypeName"
                :key="types.value"
                :value="types.value"
                :id="`option-schedule-type-${types.value}`"
              ) {{ types.text }}

        .Form-item.required.select-stock-type(v-if="isTypeTransferRequest || isTypeOrderRequest")
          CustomRadioTypes(
            @input="changedRequestStoreItemType"
            v-model="requestStoreItemType"
            id="select-stock-type"
            :data="requestStoreItemTypesList"
            )

        .Form-item.required(v-if="isTypeDespatch")
          label.Form-item-label {{ $t('ScheduledJob.popupForm_Field_SupplierName') }}
          .control
            custom-search(
              component-name="scheduled-job-search-supplier"
              name="supplier"
              v-model="supplier"
              :data-vv-as="$t('ScheduledJob.popupForm_Field_SupplierName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('supplier') }"
              :on-search="getSupplierSelectOptions"
              :options="supplierSelectOptions"
              label="name"
              :loading="isSearchSupplier"
              :placeholder="$t('ScheduledJob.popupForm_Field_SupplierName_Placeholder')"
            )

              template(slot="no-options")
                | {{ $t('ScheduledJob.popupForm_Field_SupplierName_NoResults') }}

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('supplier')"
            ) {{ veeErrors.first('supplier') }}

        .Form-item.required#schedule-form-item-select-store(v-if="isTypeDespatch")
          label.Form-item-label {{ $t('ScheduledJob.detail_Popup_FormField_StoreName') }}
          .control.form-item-select
            input.input-store-text.txt(
              name="storeList",
              v-if="isTypeDespatch"
              :placeholder="storeInputText"
              :value="form.storeList.length === 0 ? null : storeInputText"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')"
              :class="{ 'is-danger': veeErrors.has('storeList') }"
              v-validate="{required: form.storeList.length === 0}"
              readOnly
              @click="changeShowModal(true)"
            )
            Icon(name="icon-global-open-detail")
        .Form-item.required#schedule-form-item-select-store(v-if="isTypeInventory || isTypeSpotCheck || isTypeTransferRequest || isTypeOrderRequest")
          .flexbox.justify-space-between.align-item-center
            label.Form-item-label {{ $t('ScheduledJob.detail_Popup_FormField_StoreName') }}
            CustomCheckbox.m-bottom-10(
              :checked="storeListLength === selectedStores.length"
              id="checkbox-schedule-toggle-select-all-stores"
              @change="toggleAll"
              :label="$t('ScheduledJob.detail_Popup_Label_Select_All_Stores')"
            )
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('storeList')"
          ) {{ veeErrors.first('storeList') }}

          .control.form-item-select
            custom-search(
              component-name="storeList"
              name="store"
              v-model="store"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')"
              v-validate="{'required': isEmptySelectedStores}"
              :inputClass="{ 'is-danger': veeErrors.has('storeList') }"
              :on-search="searchOnStoreList"
              :options="selectOptionsStoreList"
              label="name"
              :loading="false"
              placeholder=""
            )
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('storeList')"
          ) {{ veeErrors.first('storeList') }}

          .stores-like-barcodes#toggle-selected-stores
            span.stores-like-barcodes-text.m-top-5(v-for="(store, index) in selectedStores") {{ store.name }}
              Button.stores-like-barcodes-text-remove(
                size="medium"
                :justIcon="true"
                variant="icon"
                :id="`#toggle-selected-store-${index}`"
                type="button"
                @click="removeStore(store)"
              )
                Icon(name="icon-barcode-close")

        .Form-item.required#schedule-form-item-select-item-group(v-if="isTypeTransferRequest || isTypeOrderRequest")
          .flexbox.justify-space-between.align-item-center
            label.Form-item-label {{ $t('ScheduledJob.detail_Popup_FormField_Item_Group') }}
            CustomCheckbox.m-bottom-10(
              :checked="itemGroupList.length === selectedItemGroups.length"
              id="checkbox-schedule-toggle-select-all-item-groups"
              @change="toggleAllItemGroups"
              :label="$t('ScheduledJob.detail_Popup_Label_Select_All_Item_Groups')"
            )
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('itemGroupList')"
          ) {{ veeErrors.first('itemGroupList') }}

          .control.form-item-select
            custom-search(
              component-name="itemGroupList"
              name="itemGroup"
              v-model="itemGroup"
              :data-vv-as="$t('ScheduledJob.detail_Popup_FormField_Item_Group_Name')"
              v-validate="{'required': isEmptySelectedItemGroups}"
              :inputClass="{ 'is-danger': veeErrors.has('itemGroupList') }"
              :on-search="searchOnItemGroupList"
              :options="selectOptionsItemGroupList"
              label="name"
              :loading="false"
              placeholder=""
            )
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('itemGroupList')"
          ) {{ veeErrors.first('itemGroupList') }}

          .barcodes#toggle-selected-item-groups
            span.barcodes-text.m-top-5(v-for="(itemGroup, index) in selectedItemGroups") {{ itemGroup.name }}
              Button.barcodes-text-remove(
                size="medium"
                :justIcon="true"
                variant="icon"
                :id="`#toggle-selected-item-group-${index}`"
                type="button"
                @click="removeItemGroup(itemGroup)"
              )
                Icon(name="icon-barcode-close")

        .Form-item.required#schedule-form-item-select-lists(v-if="isTypeSpotCheck")
          label.Form-item-label {{ $t('ScheduledJob.popupAdd_FormField_List') }}
          .control
            custom-search(
              component-name="search-schedule-form-list"
              name="list"
              v-model="list"
              :data-vv-as="$t('ScheduledJob.popupAdd_FormField_List') "
              v-validate="{'required': isEmptySelectedLists}"
              :inputClass="{ 'is-danger': veeErrors.has('list') }"
              :on-search="getOptionsList"
              :options="selectOptionsList"
              label="name"
              :loading="isSearchSpotLists"
              placeholder=""
              :searchAll="true"
            )

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('list')"
          ) {{ veeErrors.first('list') }}

          .stores-like-barcodes#toggle-schedule-lists
            span.stores-like-barcodes-text.m-top-5(v-for="(item, index) in selectedLists") {{ item.name }}
              Button.stores-like-barcodes-text-remove(
                size="medium"
                :justIcon="true"
                variant="icon"
                :id="`toggle-schedule-list-${index}`"
                type="button"
                @click="removeListItem(item)"
              )
                Icon(name="icon-barcode-close")

        .Form-item.Form-item-separator#schedule-form-item-creating(v-if="isTypeDespatch || !frequencyOneTime")
          .schedule.m-top-20.m-bottom-20

            .form-item-select.schedule-date
              label.schedule-date-label {{ $t('ScheduledJob.detail_Popup_Label_Schedule_Begin') }}
              .schedule-date-date.relative
                CustomDatepicker(
                  id="schedule-form-schedule-begin-date"
                  name="beginDate",
                  data-vv-as="$t('ScheduledJob.detail_Popup_Label_Schedule_Begin')"
                  v-model="form.beginDate",
                  :class="{ 'is-danger': veeErrors.has('beginDate') }"
                  class="datepicker"
                  v-validate="'required'"
                  pickerType="manuel",
                  inputIconRight="icon-datepicker-big"
                  inputIcon="",
                  position="left"
                  :disabledStartDate="disableDateBegin")

              p.Form-item-help.is-danger(
              v-show="veeErrors.has('beginDate')"
              ) {{ veeErrors.first('beginDate') }}

            .form-item-select.schedule-date.m-left-20
              div.flexbox
                label.schedule-date-label {{ $t('ScheduledJob.detail_Popup_Label_Schedule_End') }}
                CustomCheckbox(
                  :checked="checkboxEndDate"
                  id="checkbox-schedule-toggle-end-date"
                  @change="changedEndDateCheckbox"
                  label=""
                )
              .schedule-date-date.relative(v-if="checkboxEndDate")
                CustomDatepicker(
                  id="schedule-form-schedule-end-date"
                  name="endDate",
                  data-vv-as="$t('ScheduledJob.detail_Popup_Label_Schedule_Begin')"
                  v-model="form.endDate",
                  :class="{ 'is-danger': veeErrors.has('endDate') }"
                  class="datepicker"
                  v-validate="'required'"
                  pickerType="manuel",
                  inputIconRight="icon-datepicker-big"
                  inputIcon="",
                  :disabledStartDate="disableDateEnd")

              .schedule-date-date(v-else)
                input.txt(
                  disabled
                  id="schedule-form-schedule-end-date"
                  :value="$t('ScheduledJob.detail_Popup_Input_Checkbox_End_Date')"
                )

        .Form-item.Form-item-separator.creating-frequency(v-if="isTypeInventory || isTypeSpotCheck || isTypeTransferRequest || isTypeOrderRequest")
          label.Form-item-label.m-top-20.m-bottom-20 {{ $t('ScheduledJob.detail_Popup_FormField_Creating_Frequency') }}
          CustomRadioTypes(
            v-model="form.scheduledJobs[0].periodType"
            @input="changedPeriodType"
            id="select-schedule-frequency"
            :data="isTypeInventory ? creatingTimeInventoryFrequencyList : creatingTimeSpotCheckFrequencyList"
            )

        .Form-item.creating(v-if="isTypeInventory || isTypeSpotCheck || isTypeTransferRequest || isTypeOrderRequest")
          .form-item-select.creating-date(v-if="frequencyMonthly || frequencyOneTime")
            label.creating-date-label {{ $t('ScheduledJob.detail_Popup_Label_Day') }}

            .creating-date-date(
              v-if="frequencyMonthly"
            )
              input.no-spinners.txt(
              v-model.number="form.scheduledJobs[0].dayOfMonth"
              id="schedule-form-creating-day"
              name="inventory_create_day"
              v-validate="'required|numeric|between:1,31'"
              :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Day')"
              :class="{ 'is-danger': veeErrors.has('inventory_create_day') }"
              type="number")

              p.Form-item-help.is-danger(
              v-show="veeErrors.has('inventory_create_day')"
              ) {{ veeErrors.first('inventory_create_day') }}

            .creating-date-date(
              v-if="frequencyOneTime"
            )
              CustomDatepicker(
                id="schedule-one-time-date"
                name="oneTimeDate",
                :data-vv-as="$t('Request.form_RequestDate') ",
                v-model="form.scheduledJobs[0].date",
                :class="{ 'is-danger': veeErrors.has(`RequestDate`) }"
                v-validate="'required'"
                pickerType="manuel",
                inputIconRight="icon-datepicker-big"
                inputIcon="",
                @change="changedOneTimeDate",
                :position="isEdit ? 'right': 'top'"
                :disabledStartDate="disabledBeforeToday")

          .form-item-select.creating-date(v-if="(isTypeInventory || isTypeSpotCheck || isTypeTransferRequest || isTypeOrderRequest) && frequencyWeekly")
            label.creating-date-label {{ $t('ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day') }}
            select.select.m-top-10(
              v-model="form.scheduledJobs[0].dayOfWeek"
              id="select-schedule-weekly"
              v-if="frequencyWeekly"
              :data-vv-as="$t('ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day')"
              name="create_day_of_week"
              :class="{ 'is-danger': veeErrors.has('create_day_of_week') }"
              v-validate="'required|is_not:-1'"
              )
              option(
                value="-1"
                id="option-weekly-schedule-creating-not-selected"
              ) {{ $t('ScheduledJob.detail_Popup_FormField_Not_Selected') }}
              option(v-for="day in days",
              :value="day.value"
              :key="day.name"
              :id="`option-weekly-schedule-${day.name}`") {{ day.name }}

        p.Form-item-help.is-danger(
        v-show="veeErrors.has('create_day_of_week')"
        ) {{ veeErrors.first('create_day_of_week') }}

        .control.creating-time(v-if="isTypeInventory || isTypeSpotCheck || isTypeTransferRequest || isTypeOrderRequest")
          .creating-time-time
            .creating-time-hour
              label.creating-time-label {{ $t('ScheduledJob.detail_Popup_Label_Time_Hour') }}
              input.txt.no-spinners(
                v-model.number="form.scheduledJobs[0].hour"
                id="input-schedule-job-inventory-create-hour"
                name="inventory_create_hour"
                autocomplete="off"
                v-validate="'required|numeric|between:0,23'"
                :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Time_Hour')"
                :class="{ 'is-danger': veeErrors.has('inventory_create_hour') }"
                type="number"
                )
              p.Form-item-help.is-danger(
              v-show="veeErrors.has('inventory_create_hour')"
              ) {{ veeErrors.first('inventory_create_hour') }}
            .creating-time-minute
              label.creating-time-label {{ $t('ScheduledJob.detail_Popup_Label_Time_Minute') }}
              input.txt.no-spinners(
                v-model.number="form.scheduledJobs[0].minute"
                id="input-schedule-job-inventory-create-minute"
                v-validate="'required|numeric|between:0,59'"
                name="inventory_create_minute"
                autocomplete="off"
                :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Time_Minute')"
                :class="{ 'is-danger': veeErrors.has('inventory_create_minute') }"
                step="any"
                type="number"
                )
              p.Form-item-help.is-danger(
              v-show="veeErrors.has('inventory_create_minute')"
              ) {{ veeErrors.first('inventory_create_minute') }}

        .Form-item.Form-item-separator(v-if="isTypeTransferRequest || isTypeOrderRequest")
          .m-top-20.m-bottom-20.checkbox
            CustomCheckbox(
              :checked="this.isFinalize"
              id="checkbox-schedule-toggle-finalize"
              :label="$t('ScheduledJob.detail_Popup_FormField_Finalize_Checkbox')"
              @change="changedFinalizeCheckbox"
            )

        .Form-item.Form-item-separator(v-if="isTypeInventory || isTypeSpotCheck")
          .m-top-20.m-bottom-20.checkbox
            CustomCheckbox(
              :checked="this.form.scheduledJobs[1].isActive"
              id="checkbox-schedule-toggle-closing"
              :label="$t('ScheduledJob.detail_Popup_FormField_Close_Checkbox')"
              @change="changedClosedCheckbox"
            )

        .Form-item.closing(v-if="(isTypeInventory || isTypeSpotCheck) && isCheckedClosedCheckbox")
          .form-item-select.closing-date(v-if="!frequencyDaily")
            label.closing-date-label(v-if="!frequencyMonthly && !frequencyLastDay && !frequencyWeekly") {{ $t('ScheduledJob.detail_Popup_Label_Day') }}
            label.closing-date-label(v-if="frequencyMonthly || frequencyLastDay") {{ $t('ScheduledJob.detail_Popup_After_Close_Label_Day') }}
            label.closing-date-label(v-if="frequencyWeekly") {{ $t('ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day') }}

            .closing-date-date(v-if="frequencyMonthly || frequencyLastDay")
              input.no-spinners.txt(
              v-model.number="form.scheduledJobs[1].relativeDays"
              id="schedule-form-closing-day"
              name="inventory_close_day"
              v-validate="'required|numeric|between:0,28'"
              :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Day')"
              :class="{ 'is-danger': veeErrors.has('inventory_close_day') }"
              type="number")
              p.Form-item-help.is-danger(
              v-show="veeErrors.has('inventory_close_day')"
              ) {{ veeErrors.first('inventory_close_day') }}

            .closing-date-date(
              v-if="frequencyOneTime"
            )

              CustomDatepicker(
                id="schedule-one-time-close-date"
                name="oneTimeDate",
                v-model="form.scheduledJobs[1].date",
                :class="{ 'is-danger': veeErrors.has('endDate') }"
                class="datepicker"
                v-validate="'required'"
                pickerType="manuel",
                inputIconRight="icon-datepicker-big"
                inputIcon="",
                :disabledStartDate="{to: form.scheduledJobs[0].date}")

        select.select.m-top-10(
          v-model="form.scheduledJobs[1].dayOfWeek"
          id="select-schedule-weekly"
          v-if="!isTypeTransferRequest && !isTypeOrderRequest && frequencyWeekly && isCheckedClosedCheckbox"
          name="close_day_of_week"
          :data-vv-as="$t('ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day')"
          :class="{ 'is-danger': veeErrors.has('close_day_of_week') }"
          v-validate="'required|is_not:-1'"
        )
          option(
            value="-1"
            id="option-weekly-schedule-not-selected"
          ) {{ $t('ScheduledJob.detail_Popup_FormField_Not_Selected') }}
          option(v-for="day in days",
          :value="day.value"
          :key="day.name"
          :id="`option-weekly-schedule-${day.name}`") {{ day.name }}

        p.Form-item-help.is-danger(
        v-show="veeErrors.has('close_day_of_week')"
        ) {{ veeErrors.first('close_day_of_week') }}

        .control.closing-time(v-if="(isTypeInventory || isTypeSpotCheck) && isCheckedClosedCheckbox")
          .closing-time-time
            .closing-time-hour
              label.closing-time-label {{ $t('ScheduledJob.detail_Popup_Label_Time_Hour') }}
              input.txt.no-spinners(
                v-model.number="form.scheduledJobs[1].RelativeHours"
                v-if="frequencyDaily"
                id="input-schedule-job-inventory-close-hour-freq-daily"
                name="inventory_close_hour"
                autocomplete="off"
                v-validate="'required|numeric|between:0,23'"
                :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Time_Hour')"
                :class="{ 'is-danger': veeErrors.has('inventory_close_hour') }"
                type="number"
                )
              input.txt.no-spinners(
                v-model.number="form.scheduledJobs[1].hour"
                v-else
                id="input-schedule-job-inventory-close-hour"
                name="inventory_close_hour"
                autocomplete="off"
                v-validate="'required|numeric|between:0,23'"
                :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Time_Hour')"
                :class="{ 'is-danger': veeErrors.has('inventory_close_hour') }"
                type="number"
                )
              p.Form-item-help.is-danger(
              v-show="veeErrors.has('inventory_close_hour')"
              ) {{ veeErrors.first('inventory_close_hour') }}
            .closing-time-minute
              label.closing-time-label {{ $t('ScheduledJob.detail_Popup_Label_Time_Minute') }}
              input.txt.no-spinners(
                v-model.number="form.scheduledJobs[1].minute"
                id="input-schedule-job-inventory-close-minute"
                v-validate="'required|numeric|between:0,59'"
                name="inventory_close_minute"
                autocomplete="off"
                :data-vv-as="$t('ScheduledJob.detail_Popup_Label_Time_Minute')"
                :class="{ 'is-danger': veeErrors.has('inventory_close_minute') }"
                step="any"
                type="number"
                )
              p.Form-item-help.is-danger(
              v-show="veeErrors.has('inventory_close_minute')"
              ) {{ veeErrors.first('inventory_close_minute') }}

    template(slot="footer")
      Button(
        id="btn-schedule-job-submit",
        variant="full",
        success,
        :disabled="isLoadingCreate"
        type="submit",
        form="form-scheduled-job",
        :isLoading="isLoadingCreate"
      )
        span {{ $t('ScheduledJob.detail_Popup_Button_Submit') }}

</template>

<script>
import CustomRadioTypes from '@/view/global/multiselect-radio'
import matchSearch from '@/utils/matchingSearch'
import moment from 'moment'
import { mapActions } from 'vuex'
import getDays from '@/utils/getDays'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  components: {
    CustomRadioTypes
  },

  watch: {
    store () {
      this.addStoreToList()
    },
    list () {
      this.addListToList()
    },
    itemGroup () {
      this.addItemGroupToList()
    }
  },

  data () {
    return {
      showModal: false,
      search: '',
      selectOptionsStoreList: [],
      selectOptionsItemGroupList: [],
      requestStoreItemType: null,
      store: null,
      itemGroup: null,
      list: null,
      supplier: null,
      storeList: [],
      itemGroupList: [],
      selectedItemGroupList: [],
      selectedLists: [],
      selectOptionsList: [],
      supplierSelectOptions: [],
      checkboxEndDate: true,
      days: getDays(),
      isFinalize: false,
      storeListItems: [],
      form: {
        scheduleTypeName: 'SpotCheck',
        beginDate: new Date(),
        endDate: new Date(),
        isActive: true,
        storeList: [],
        parameters: [],
        scheduledJobs: [
          {
            jobTypeName: 'OpenSpotCheck',
            periodType: 1,
            dayOfMonth: null,
            dayOfWeek: -1,
            RelativeHours: null,
            date: new Date(),
            hour: null,
            minute: null,
            isActive: true
          },
          {
            jobTypeName: 'CloseSpotCheck',
            periodType: 1,
            date: new Date(),
            relativeDays: null,
            dayOfWeek: -1,
            RelativeHours: null,
            hour: null,
            minute: null,
            isActive: true
          }
        ]
      },
      scheduleTypeName: [
        {
          text: this.$t('ScheduledJob.Item_Type_Spot_Inventory'),
          value: 'SpotCheck'
        },
        {
          text: this.$t('ScheduledJob.Item_Type_Inventory'),
          value: 'Inventory'
        },
        {
          text: this.$t('ScheduledJob.Item_Type_Despatch'),
          value: 'Despatch'
        },
        {
          text: this.$t('ScheduledJob.Item_Type_Transfer_Request'),
          value: 'TransferRequest'
        },
        {
          text: this.$t('ScheduledJob.Item_Type_Order_Request'),
          value: 'OrderRequest'
        }
      ],
      creatingTimeInventoryFrequencyList: [
        {
          text: this.$t('ScheduledJob.Item_Assign_Day'),
          value: 5
        },
        {
          text: this.$t('ScheduledJob.Item_Last_Day_Of_Month'),
          value: 6
        }
      ],
      creatingTimeSpotCheckFrequencyList: [
        {
          text: this.$t('ScheduledJob.Item_Assign_One_Time'),
          value: 1
        },
        {
          text: this.$t('ScheduledJob.Item_Assign_Daily'),
          value: 3
        },
        {
          text: this.$t('ScheduledJob.Item_Assign_Weekly'),
          value: 4
        }
      ],
      requestStoreItemTypesList: [
        {
          text: this.$t('ScheduledJob.Item_Stock_Type_Min'),
          value: 1
        },
        {
          text: this.$t('ScheduledJob.Item_Stock_Type_Opt'),
          value: 2
        }
      ]
    }
  },

  beforeMount () {
    this.init()
  },

  computed: {
    isEdit () {
      return false
    },

    storeInputText () {
      if (this.form.storeList.length === 0) {
        return this.$t('ScheduledJob.Placeholder_Store_Text')
      } else {
        if (this.form.storeList.length === 1) {
          return this.form.storeList[0].name
        } else {
          return this.form.storeList.map(item => item.name).join(', ')
        }
      }
    },

    isSearchSupplier () {
      return this.$wait.is('searchSupplier')
    },

    isSearchSpotLists () {
      return this.$wait.is('searchSpotLists')
    },

    isValidEndDate () {
      return this.checkboxEndDate &&
      this.form.endDate &&
      this.veeErrors.has('endDate')
    },

    isEmptySelectedStores () {
      return this.selectedStores.length === 0
    },

    isEmptySelectedItemGroups () {
      return this.selectedItemGroups.length === 0
    },

    isEmptySelectedLists () {
      return this.selectedLists.length === 0
    },

    isCheckedClosedCheckbox () {
      return this.form.scheduledJobs[1].isActive
    },

    isTypeInventory () {
      return this.form.scheduleTypeName === 'Inventory'
    },

    isTypeSpotCheck () {
      return this.form.scheduleTypeName === 'SpotCheck'
    },

    isTypeDespatch () {
      return this.form.scheduleTypeName === 'Despatch'
    },

    isTypeTransferRequest () {
      return this.form.scheduleTypeName === 'TransferRequest'
    },

    isTypeOrderRequest () {
      return this.form.scheduleTypeName === 'OrderRequest'
    },

    frequencyMonthly () {
      return this.form.scheduledJobs[0].periodType === 5
    },

    frequencyLastDay () {
      return this.form.scheduledJobs[0].periodType === 6
    },

    frequencyOneTime () {
      return this.form.scheduledJobs[0].periodType === 1
    },

    frequencyWeekly () {
      return this.form.scheduledJobs[0].periodType === 4
    },

    frequencyDaily () {
      return this.form.scheduledJobs[0].periodType === 3
    },

    disableDateBegin () {
      return { to: moment()._d, from: !this.form.endDate ? null : this.form.endDate }
    },

    disabledBeforeToday () {
      return {to: moment()._d}
    },

    isLoadingCreate () {
      return this.$wait.is(['createScheduleJob', 'onSubmitFormScheduledJob'])
    },

    disableDateEnd () {
      return { to: this.form.beginDate }
    },

    selectedStores () {
      return this.storeListItems.filter(store => store.isChecked)
    },

    selectedItemGroups () {
      return this.itemGroupList.filter(itemGroup => itemGroup.isChecked)
    },

    storeListLength () {
      return this.isTypeSpotCheck ? this.storeList.filter(i => i.type !== 3).length : this.storeList.length
    }
  },

  methods: {
    ...mapActions('ScheduleJob', [
      'createScheduleJob'
    ]),
    ...mapActions('Supplier', [
      'searchSupplier'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Group', ['getItemGroupAll']),

    ...mapActions('Stores', ['getStoreList']),

    ...mapActions('ListsInventory', ['searchSpotLists']),

    changedOneTimeDate () {
      if (this.form.scheduledJobs[1]) this.form.scheduledJobs[1].date = this.form.scheduledJobs[0].date
    },

    filterStoreByType () {
      this.storeListItems = this.isTypeSpotCheck ? this.storeList.filter(i => i.type !== 3) : this.storeList
    },

    init () {
      this.getStoreList({pageNumber: 1, pageSize: 9999})
        .then(res => {
          if (res) {
            this.storeList = res.data.storeList.map(item => {
              return {
                storeId: item.id,
                name: item.name,
                parameter1: null,
                parameter2: null,
                isSearched: true,
                isChecked: false,
                addedBefore: false,
                type: item.type
              }
            })
            this.storeListItems = [...this.storeList]
            this.filterStoreByType()
            this.selectOptionsStoreList = this.storeListItems
          }
        })
      this.getItemGroupAll()
        .then(res => {
          if (res) {
            this.itemGroupList = res.data.itemGroupList.map(item => {
              return {
                id: item.id,
                name: item.name,
                isChecked: true
              }
            })
            this.selectOptionsItemGroupList = this.itemGroupList
          }
        })
    },

    submitModal () {
      this.$validator.validateAll('modal')
        .then(res => {
          if (!res) return
          this.selectedStores.map(item => {
            item.addedBefore = true
          })
          this.form.storeList = this.selectedStores
          this.changeShowModal(false)
        })
    },

    changeShowModal (isChecked = false) {
      if (isChecked) {
        this.search = ''
        this.storeListItems.map(item => {
          if (!item.addedBefore || !item.isChecked) {
            item.parameter1 = null
            item.parameter2 = null
            item.isChecked = false
          }
        })
        this.selectOptionsStoreList = this.storeListItems
      }
      this.showModal = isChecked
    },

    changedEndDateCheckbox (isChecked) {
      if (!isChecked) this.form.endDate = null
      this.checkboxEndDate = isChecked
    },

    changedFinalizeCheckbox (isChecked) {
      this.isFinalize = isChecked
    },

    toggleAll (isChecked) {
      this.storeListItems.map(item => isChecked ? this.addStoreToList(item) : this.removeStore(item))
    },

    toggleAllItemGroups (isChecked) {
      this.itemGroupList.map(item => isChecked ? this.addItemGroupToList(item) : this.removeItemGroup(item))
    },

    removeListItem (list) {
      const index = this.selectedLists.findIndex(item => item.id === list.id)
      this.selectedLists.splice(index, 1)
    },

    getOptionsList (search) {
      this.searchSpotLists(search)
        .then(res => {
          if (res) {
            this.selectOptionsList = res.data.inventoryListHeaderList
          }
        })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    changedClosedCheckbox (isChecked) {
      this.form.scheduledJobs[1].isActive = isChecked
    },

    searchOnStoreList (search) {
      this.selectOptionsStoreList = this.storeListItems.filter(item => matchSearch(item.name, search))
    },

    searchOnItemGroupList (search) {
      this.selectOptionsItemGroupList = this.itemGroupList.filter(item => matchSearch(item.name, search))
    },

    addStoreToList (store = this.store) {
      if (store !== null) store.isChecked = true
    },

    addItemGroupToList (itemGroup = this.itemGroup) {
      itemGroup.isChecked = true
    },

    addListToList () {
      if (!this.list || this.selectedLists.indexOf(this.list) > -1) return
      this.selectedLists.push(this.list)
    },

    removeStore (store) {
      store.isChecked = false
    },

    removeItemGroup (itemGroup) {
      itemGroup.isChecked = false
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.createScheduledJob()
    }, 'onSubmitFormScheduledJob'),

    async createScheduledJob () {
      await this.$validator.validateAll()
        .then(async result => {
          if (!result) return

          if (this.isTypeDespatch) {
            this.form.parameters = [{
              key: 'Supplier',
              value: this.supplier.id
            }]
            this.form.scheduledJobs = []
          }
          if (this.isTypeInventory || this.isTypeSpotCheck) {
            this.form.storeList = this.selectedStores.map(i => {
              return {
                storeId: i.storeId,
                parameter1: i.parameter1,
                parameter2: i.parameter2
              }
            })
            this.form.scheduledJobs[1].periodType = this.frequencyDaily ? 7 : this.form.scheduledJobs[0].periodType
            this.form.scheduledJobs[1].periodType = this.frequencyLastDay || this.frequencyMonthly ? 8 : this.form.scheduledJobs[1].periodType
            this.form.parameters = this.isTypeInventory
              ? []
              : this.selectedLists.map(item => { return {key: 'List', value: item.id} })
            this.form.endDate = this.frequencyOneTime ? null : this.form.endDate
            this.form.scheduledJobs[0].date = moment(this.form.scheduledJobs[0].date).format('YYYY-MM-DD')
            this.form.scheduledJobs[1].date = moment(this.form.scheduledJobs[1].date).format('YYYY-MM-DD')
          }
          if (this.isTypeTransferRequest || this.isTypeOrderRequest) {
            this.form.storeList = this.selectedStores.map(i => {
              return {
                storeId: i.storeId,
                parameter1: i.parameter1,
                parameter2: i.parameter2
              }
            })
            this.form.parameters.push({ key: 'RequestStoreItemType', value: this.requestStoreItemType.toString() })
            this.form.parameters.push({ key: 'Finalize', value: this.isFinalize.toString() })
            this.selectedItemGroups.map(item => { this.form.parameters.push({key: 'ItemGroup', value: item.id}) })
            this.form.scheduledJobs = [ this.form.scheduledJobs[0] ]
            this.form.endDate = this.frequencyOneTime ? null : this.form.endDate
            this.form.scheduledJobs[0].date = moment(this.form.scheduledJobs[0].date).format('YYYY-MM-DD')
          }

          this.form.beginDate = moment(this.form.beginDate).format('YYYY-MM-DD')
          this.form.endDate = this.form.endDate ? moment(this.form.endDate).format('YYYY-MM-DD') : null
          await this.createScheduleJob(this.form)
            .then(res => {
              if (!res) return
              const message = this.$t('ScheduledJob.popup_Notificiation_Created')
              this.notifyShow({ message })
              this.$emit('getList')
              this.close()
            })
        })
    },

    changedPeriodType () {
      this.form.scheduledJobs = [
        {
          jobTypeName: this.isTypeTransferRequest || this.isTypeOrderRequest ? 'InsertRequest' : this.form.scheduledJobs[0].jobTypeName,
          periodType: this.form.scheduledJobs[0].periodType,
          dayOfMonth: null,
          dayOfWeek: -1,
          RelativeHours: null,
          date: new Date(),
          hour: null,
          minute: null,
          isActive: true
        },
        {
          jobTypeName: this.isTypeTransferRequest || this.isTypeOrderRequest ? null : this.form.scheduledJobs[1].jobTypeName,
          periodType: this.isTypeTransferRequest || this.isTypeOrderRequest ? null : this.form.scheduledJobs[1].periodType,
          date: new Date(),
          relativeDays: null,
          dayOfWeek: -1,
          RelativeHours: null,
          hour: null,
          minute: null,
          isActive: true
        }
      ]
    },

    changedRequestStoreItemType (value) {
      this.requestStoreItemType = value
    },

    resetSelectedStoreItems () {
      this.storeListItems.map((item) => { item.isChecked = false })
    },

    resetForm () {
      this.$validator.reset()
      this.resetSelectedStoreItems()
      this.filterStoreByType()
      this.selectOptionsStoreList = []
      this.store = null
      this.requestStoreItemType = this.isTypeTransferRequest || this.isTypeOrderRequest ? 1 : null
      this.itemGroup = null
      this.list = null
      this.selectedLists = []
      this.selectOptionsList = []
      this.checkboxEndDate = true
      this.isFinalize = false
      this.form = {
        scheduleTypeName: this.form.scheduleTypeName,
        beginDate: new Date(),
        endDate: new Date(),
        isActive: true,
        storeList: [],
        parameters: [],
        scheduledJobs: [
          {
            jobTypeName: this.isTypeInventory ? 'OpenInventory' : this.isTypeTransferRequest || this.isTypeOrderRequest ? 'InsertRequest' : 'OpenSpotCheck',
            periodType: this.isTypeInventory ? 5 : 1,
            dayOfMonth: null,
            dayOfWeek: -1,
            RelativeHours: null,
            date: new Date(),
            hour: null,
            minute: null,
            isActive: true
          },
          {
            jobTypeName: this.isTypeInventory ? 'CloseInventory' : 'CloseSpotCheck',
            periodType: this.isTypeInventory ? 8 : 1,
            date: new Date(),
            relativeDays: null,
            dayOfWeek: -1,
            RelativeHours: null,
            hour: null,
            minute: null,
            isActive: true
          }
        ]
      }
    },

    getSupplierSelectOptions (search) {
      this.searchSupplier(search)
        .then(res => {
          if (res.status === 200) {
            this.supplierSelectOptions = res.data.items
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>

@import '~@/view/pages/Lists/styles/form-modal.scss';

.creating,
.closing,
.schedule {
  display: flex;
  justify-content: space-between;

  &-date,
  &-time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    &-time {
      display: flex;
      flex-direction: row;
    }

    &-hour,
    &-minute {
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);
    }
    &-minute {
      margin-left: 20px;
    }

    label {
      margin-left: 10px;
      margin-bottom: 10px;
      color: $color-light;
    }
  }

  &-time {
    justify-content: flex-start;
    margin: 20px 0;
  }
}

.checkbox {

  label {
    justify-content: flex-start !important;
  }
}
.closing-info {
  color: $color-dark;
}
.ListModal {
  .Modal-Listitems {
    .row {
        grid-template-columns: repeat(3, 1fr);

      .parameter {
        width: 50%;
      }
    }
  }
}
.input-store-text {
  cursor: pointer;
}
.form-item-select .icon-global-open-detail {
  $icon-width: 20px;
  height: $icon-width;
  width: $icon-width;
  position: absolute;
  right: 20px;
  top: calc(50% - #{$icon-width / 2});
  pointer-events: none;
}
</style>
