import moment from 'moment'

function formatDate(date) {
  return !date ? null : moment(date).format('YYYY-MM-DD')
}

export default function(item, $t) {
  const items = item.scheduledJobs.filter(element => element.isActive).map(element => $t(`ScheduledJob.Col_scheduleType_${element.jobTypeName}`))

  this.id = item.id
  this.parameters = item.parameters.length ? item.parameters[0].info : null
  this.supplier = item.parameters.length ? item.parameters[0].info : null
  this.canBeActivated = item.canBeActivated
  this.showPopup = false
  this.reason = ''
  this.dontShowSwitchIcon = !item.hasError || !item.isActive
  this.scheduleTypeName = $t(`ScheduledJob.Col_scheduleTypeName_${item.scheduleTypeName}`)
  this.period = item.scheduleTypeName === 'Despatch' ? null : $t(`ScheduledJob.Col_Period_Type_${item.scheduledJobs[0].periodType}`) // elden geçecek
  this.operation = items.length === 1 ? items[0] : items.join(' - ')
  this.nextScheduledDate = formatDate(item.nextScheduledDate)
  this.lastExecutionDate = formatDate(item.lastExecutionDate)
  this.beginDate = formatDate(item.beginDate)
  this.endDate = formatDate(item.endDate)
  this.isActive = item.isActive
  this.status = item.hasError ? 1 : 2
  this.showDetail = false
  this.detailList = item.detailList || []
}
