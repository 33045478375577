var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("ScheduledJob.detail_Popup_Title_Inventory"))),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "Modal",
            {
              staticClass: "ListModal",
              attrs: { isOpen: _vm.showModal },
              on: {
                closeModal: function ($event) {
                  return _vm.changeShowModal(false)
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("ScheduledJob.StoreList"))),
              ]),
              _c("template", { slot: "content" }, [
                _c(
                  "form",
                  {
                    staticClass: "Modal-Listitems",
                    attrs: {
                      "data-vv-scope": "modal",
                      id: "modal-schedule-store-modal",
                    },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitModal.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "Search" }, [
                      _c(
                        "div",
                        { staticClass: "icon-field" },
                        [
                          _c("Icon", {
                            staticClass: "icon-search",
                            attrs: { name: "icon-search" },
                          }),
                        ],
                        1
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          id: "input-schedule-store-search-in-list",
                          placeholder: _vm.$t(
                            "ScheduledJob.detail_Store_Search"
                          ),
                        },
                        domProps: { value: _vm.search },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.search = $event.target.value
                            },
                            function ($event) {
                              return _vm.searchOnStoreList(_vm.search)
                            },
                          ],
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "row header" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("CustomCheckbox", {
                            attrs: {
                              checked:
                                _vm.storeListLength ===
                                _vm.selectedStores.length,
                              disabled: _vm.search !== "",
                              label: _vm.$t(
                                "ScheduledJob.detail_Popup_FormField_StoreName"
                              ),
                            },
                            on: { change: _vm.toggleAll },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ScheduledJob.detail_Popup_FormField_Parameter"
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ScheduledJob.detail_Popup_FormField_Parameter_2"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.selectOptionsStoreList, function (item, index) {
                      return item.isSearched
                        ? _c(
                            "div",
                            {
                              key: `${item.id}-${item.name}`,
                              staticClass: "row",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c("CustomCheckbox", {
                                    attrs: { label: item.name },
                                    model: {
                                      value: item.isChecked,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isChecked", $$v)
                                      },
                                      expression: "item.isChecked",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.parameter1,
                                      expression: "item.parameter1",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        item.isChecked && !item.parameter2
                                          ? "required|integer|min_value:1"
                                          : "",
                                      expression:
                                        "item.isChecked && !item.parameter2 ? 'required|integer|min_value:1' : ''",
                                    },
                                  ],
                                  staticClass:
                                    "txt parameter ff-mono no-spinners",
                                  class: {
                                    "is-danger": _vm.veeErrors.has(
                                      `modal.parameter1-${index}`
                                    ),
                                  },
                                  attrs: {
                                    name: `parameter1-${index}`,
                                    autocomplete: "off",
                                    type: "number",
                                  },
                                  domProps: { value: item.parameter1 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "parameter1",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "col" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        item.isChecked && !item.parameter1
                                          ? "required|integer|between:0,23"
                                          : "",
                                      expression:
                                        "item.isChecked && !item.parameter1 ? 'required|integer|between:0,23' : ''",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.parameter2,
                                      expression: "item.parameter2",
                                    },
                                  ],
                                  staticClass:
                                    "txt parameter ff-mono no-spinners",
                                  class: {
                                    "is-danger": _vm.veeErrors.has(
                                      `modal.parameter2-${index}`
                                    ),
                                  },
                                  attrs: {
                                    name: `parameter2-${index}`,
                                    autocomplete: "off",
                                    type: "number",
                                  },
                                  domProps: { value: item.parameter2 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "parameter2",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "modalFooter" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "uppercase addButton",
                        attrs: {
                          primary: "",
                          type: "submit",
                          disabled: _vm.selectedStores.length === 0,
                          id: "button-modal-schedule-store-modal",
                          form: "modal-schedule-store-modal",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("ScheduledJob.detail_Popup_FormField_Add")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "form",
            {
              staticClass: "Form",
              attrs: { id: "form-scheduled-job" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("ScheduledJob.popupForm_Field_ScheduleType"))
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "Form-item",
                  attrs: { id: "schedule-form-item-select-inventory-type" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("Icon", {
                        staticClass: "icon-down-arrow",
                        attrs: { name: "icon-down-arrow" },
                      }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.scheduleTypeName,
                              expression: "form.scheduleTypeName",
                            },
                          ],
                          staticClass: "select",
                          attrs: { id: "select-schedule-types" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "scheduleTypeName",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.resetForm,
                            ],
                          },
                        },
                        _vm._l(_vm.scheduleTypeName, function (types) {
                          return _c(
                            "option",
                            {
                              key: types.value,
                              attrs: {
                                id: `option-schedule-type-${types.value}`,
                              },
                              domProps: { value: types.value },
                            },
                            [_vm._v(_vm._s(types.text))]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.isTypeTransferRequest || _vm.isTypeOrderRequest
                ? _c(
                    "div",
                    { staticClass: "Form-item required select-stock-type" },
                    [
                      _c("CustomRadioTypes", {
                        attrs: {
                          id: "select-stock-type",
                          data: _vm.requestStoreItemTypesList,
                        },
                        on: { input: _vm.changedRequestStoreItemType },
                        model: {
                          value: _vm.requestStoreItemType,
                          callback: function ($$v) {
                            _vm.requestStoreItemType = $$v
                          },
                          expression: "requestStoreItemType",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isTypeDespatch
                ? _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ScheduledJob.popupForm_Field_SupplierName")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "custom-search",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              "component-name": "scheduled-job-search-supplier",
                              name: "supplier",
                              "data-vv-as": _vm.$t(
                                "ScheduledJob.popupForm_Field_SupplierName"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("supplier"),
                              },
                              "on-search": _vm.getSupplierSelectOptions,
                              options: _vm.supplierSelectOptions,
                              label: "name",
                              loading: _vm.isSearchSupplier,
                              placeholder: _vm.$t(
                                "ScheduledJob.popupForm_Field_SupplierName_Placeholder"
                              ),
                            },
                            model: {
                              value: _vm.supplier,
                              callback: function ($$v) {
                                _vm.supplier = $$v
                              },
                              expression: "supplier",
                            },
                          },
                          [
                            _c("template", { slot: "no-options" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "ScheduledJob.popupForm_Field_SupplierName_NoResults"
                                  )
                                )
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("supplier"),
                                expression: "veeErrors.has('supplier')",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [_vm._v(_vm._s(_vm.veeErrors.first("supplier")))]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTypeDespatch
                ? _c(
                    "div",
                    {
                      staticClass: "Form-item required",
                      attrs: { id: "schedule-form-item-select-store" },
                    },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ScheduledJob.detail_Popup_FormField_StoreName"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _vm.isTypeDespatch
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: _vm.form.storeList.length === 0,
                                    },
                                    expression:
                                      "{required: form.storeList.length === 0}",
                                  },
                                ],
                                staticClass: "input-store-text txt",
                                class: {
                                  "is-danger": _vm.veeErrors.has("storeList"),
                                },
                                attrs: {
                                  name: "storeList",
                                  placeholder: _vm.storeInputText,
                                  "data-vv-as": _vm.$t(
                                    "Despatches.detail_Popup_FormField_StoreName"
                                  ),
                                  readOnly: "",
                                },
                                domProps: {
                                  value:
                                    _vm.form.storeList.length === 0
                                      ? null
                                      : _vm.storeInputText,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeShowModal(true)
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("Icon", {
                            attrs: { name: "icon-global-open-detail" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isTypeInventory ||
              _vm.isTypeSpotCheck ||
              _vm.isTypeTransferRequest ||
              _vm.isTypeOrderRequest
                ? _c(
                    "div",
                    {
                      staticClass: "Form-item required",
                      attrs: { id: "schedule-form-item-select-store" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flexbox justify-space-between align-item-center",
                        },
                        [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ScheduledJob.detail_Popup_FormField_StoreName"
                                )
                              )
                            ),
                          ]),
                          _c("CustomCheckbox", {
                            staticClass: "m-bottom-10",
                            attrs: {
                              checked:
                                _vm.storeListLength ===
                                _vm.selectedStores.length,
                              id: "checkbox-schedule-toggle-select-all-stores",
                              label: _vm.$t(
                                "ScheduledJob.detail_Popup_Label_Select_All_Stores"
                              ),
                            },
                            on: { change: _vm.toggleAll },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("storeList"),
                              expression: "veeErrors.has('storeList')",
                            },
                          ],
                          staticClass: "Form-item-help is-danger",
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("storeList")))]
                      ),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.isEmptySelectedStores },
                                expression:
                                  "{'required': isEmptySelectedStores}",
                              },
                            ],
                            attrs: {
                              "component-name": "storeList",
                              name: "store",
                              "data-vv-as": _vm.$t(
                                "Despatches.detail_Popup_FormField_StoreName"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("storeList"),
                              },
                              "on-search": _vm.searchOnStoreList,
                              options: _vm.selectOptionsStoreList,
                              label: "name",
                              loading: false,
                              placeholder: "",
                            },
                            model: {
                              value: _vm.store,
                              callback: function ($$v) {
                                _vm.store = $$v
                              },
                              expression: "store",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("storeList"),
                              expression: "veeErrors.has('storeList')",
                            },
                          ],
                          staticClass: "Form-item-help is-danger",
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("storeList")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "stores-like-barcodes",
                          attrs: { id: "toggle-selected-stores" },
                        },
                        _vm._l(_vm.selectedStores, function (store, index) {
                          return _c(
                            "span",
                            {
                              staticClass: "stores-like-barcodes-text m-top-5",
                            },
                            [
                              _vm._v(_vm._s(store.name)),
                              _c(
                                "Button",
                                {
                                  staticClass:
                                    "stores-like-barcodes-text-remove",
                                  attrs: {
                                    size: "medium",
                                    justIcon: true,
                                    variant: "icon",
                                    id: `#toggle-selected-store-${index}`,
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeStore(store)
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    attrs: { name: "icon-barcode-close" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isTypeTransferRequest || _vm.isTypeOrderRequest
                ? _c(
                    "div",
                    {
                      staticClass: "Form-item required",
                      attrs: { id: "schedule-form-item-select-item-group" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flexbox justify-space-between align-item-center",
                        },
                        [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ScheduledJob.detail_Popup_FormField_Item_Group"
                                )
                              )
                            ),
                          ]),
                          _c("CustomCheckbox", {
                            staticClass: "m-bottom-10",
                            attrs: {
                              checked:
                                _vm.itemGroupList.length ===
                                _vm.selectedItemGroups.length,
                              id: "checkbox-schedule-toggle-select-all-item-groups",
                              label: _vm.$t(
                                "ScheduledJob.detail_Popup_Label_Select_All_Item_Groups"
                              ),
                            },
                            on: { change: _vm.toggleAllItemGroups },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("itemGroupList"),
                              expression: "veeErrors.has('itemGroupList')",
                            },
                          ],
                          staticClass: "Form-item-help is-danger",
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("itemGroupList")))]
                      ),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: _vm.isEmptySelectedItemGroups,
                                },
                                expression:
                                  "{'required': isEmptySelectedItemGroups}",
                              },
                            ],
                            attrs: {
                              "component-name": "itemGroupList",
                              name: "itemGroup",
                              "data-vv-as": _vm.$t(
                                "ScheduledJob.detail_Popup_FormField_Item_Group_Name"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("itemGroupList"),
                              },
                              "on-search": _vm.searchOnItemGroupList,
                              options: _vm.selectOptionsItemGroupList,
                              label: "name",
                              loading: false,
                              placeholder: "",
                            },
                            model: {
                              value: _vm.itemGroup,
                              callback: function ($$v) {
                                _vm.itemGroup = $$v
                              },
                              expression: "itemGroup",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("itemGroupList"),
                              expression: "veeErrors.has('itemGroupList')",
                            },
                          ],
                          staticClass: "Form-item-help is-danger",
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("itemGroupList")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "barcodes",
                          attrs: { id: "toggle-selected-item-groups" },
                        },
                        _vm._l(
                          _vm.selectedItemGroups,
                          function (itemGroup, index) {
                            return _c(
                              "span",
                              { staticClass: "barcodes-text m-top-5" },
                              [
                                _vm._v(_vm._s(itemGroup.name)),
                                _c(
                                  "Button",
                                  {
                                    staticClass: "barcodes-text-remove",
                                    attrs: {
                                      size: "medium",
                                      justIcon: true,
                                      variant: "icon",
                                      id: `#toggle-selected-item-group-${index}`,
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItemGroup(itemGroup)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      attrs: { name: "icon-barcode-close" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isTypeSpotCheck
                ? _c(
                    "div",
                    {
                      staticClass: "Form-item required",
                      attrs: { id: "schedule-form-item-select-lists" },
                    },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("ScheduledJob.popupAdd_FormField_List"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.isEmptySelectedLists },
                                expression:
                                  "{'required': isEmptySelectedLists}",
                              },
                            ],
                            attrs: {
                              "component-name": "search-schedule-form-list",
                              name: "list",
                              "data-vv-as": _vm.$t(
                                "ScheduledJob.popupAdd_FormField_List"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("list"),
                              },
                              "on-search": _vm.getOptionsList,
                              options: _vm.selectOptionsList,
                              label: "name",
                              loading: _vm.isSearchSpotLists,
                              placeholder: "",
                              searchAll: true,
                            },
                            model: {
                              value: _vm.list,
                              callback: function ($$v) {
                                _vm.list = $$v
                              },
                              expression: "list",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("list"),
                              expression: "veeErrors.has('list')",
                            },
                          ],
                          staticClass: "Form-item-help is-danger",
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("list")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "stores-like-barcodes",
                          attrs: { id: "toggle-schedule-lists" },
                        },
                        _vm._l(_vm.selectedLists, function (item, index) {
                          return _c(
                            "span",
                            {
                              staticClass: "stores-like-barcodes-text m-top-5",
                            },
                            [
                              _vm._v(_vm._s(item.name)),
                              _c(
                                "Button",
                                {
                                  staticClass:
                                    "stores-like-barcodes-text-remove",
                                  attrs: {
                                    size: "medium",
                                    justIcon: true,
                                    variant: "icon",
                                    id: `toggle-schedule-list-${index}`,
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeListItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    attrs: { name: "icon-barcode-close" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isTypeDespatch || !_vm.frequencyOneTime
                ? _c(
                    "div",
                    {
                      staticClass: "Form-item Form-item-separator",
                      attrs: { id: "schedule-form-item-creating" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "schedule m-top-20 m-bottom-20" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-item-select schedule-date" },
                            [
                              _c(
                                "label",
                                { staticClass: "schedule-date-label" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "ScheduledJob.detail_Popup_Label_Schedule_Begin"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "schedule-date-date relative" },
                                [
                                  _c("CustomDatepicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "datepicker",
                                    class: {
                                      "is-danger":
                                        _vm.veeErrors.has("beginDate"),
                                    },
                                    attrs: {
                                      id: "schedule-form-schedule-begin-date",
                                      name: "beginDate",
                                      "data-vv-as":
                                        "$t('ScheduledJob.detail_Popup_Label_Schedule_Begin')",
                                      pickerType: "manuel",
                                      inputIconRight: "icon-datepicker-big",
                                      inputIcon: "",
                                      position: "left",
                                      disabledStartDate: _vm.disableDateBegin,
                                    },
                                    model: {
                                      value: _vm.form.beginDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "beginDate", $$v)
                                      },
                                      expression: "form.beginDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has("beginDate"),
                                      expression: "veeErrors.has('beginDate')",
                                    },
                                  ],
                                  staticClass: "Form-item-help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.veeErrors.first("beginDate"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-item-select schedule-date m-left-20",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flexbox" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "schedule-date-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "ScheduledJob.detail_Popup_Label_Schedule_End"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("CustomCheckbox", {
                                    attrs: {
                                      checked: _vm.checkboxEndDate,
                                      id: "checkbox-schedule-toggle-end-date",
                                      label: "",
                                    },
                                    on: { change: _vm.changedEndDateCheckbox },
                                  }),
                                ],
                                1
                              ),
                              _vm.checkboxEndDate
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "schedule-date-date relative",
                                    },
                                    [
                                      _c("CustomDatepicker", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "datepicker",
                                        class: {
                                          "is-danger":
                                            _vm.veeErrors.has("endDate"),
                                        },
                                        attrs: {
                                          id: "schedule-form-schedule-end-date",
                                          name: "endDate",
                                          "data-vv-as":
                                            "$t('ScheduledJob.detail_Popup_Label_Schedule_Begin')",
                                          pickerType: "manuel",
                                          inputIconRight: "icon-datepicker-big",
                                          inputIcon: "",
                                          disabledStartDate: _vm.disableDateEnd,
                                        },
                                        model: {
                                          value: _vm.form.endDate,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "endDate", $$v)
                                          },
                                          expression: "form.endDate",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "schedule-date-date" },
                                    [
                                      _c("input", {
                                        staticClass: "txt",
                                        attrs: {
                                          disabled: "",
                                          id: "schedule-form-schedule-end-date",
                                        },
                                        domProps: {
                                          value: _vm.$t(
                                            "ScheduledJob.detail_Popup_Input_Checkbox_End_Date"
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isTypeInventory ||
              _vm.isTypeSpotCheck ||
              _vm.isTypeTransferRequest ||
              _vm.isTypeOrderRequest
                ? _c(
                    "div",
                    {
                      staticClass:
                        "Form-item Form-item-separator creating-frequency",
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "Form-item-label m-top-20 m-bottom-20" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ScheduledJob.detail_Popup_FormField_Creating_Frequency"
                              )
                            )
                          ),
                        ]
                      ),
                      _c("CustomRadioTypes", {
                        attrs: {
                          id: "select-schedule-frequency",
                          data: _vm.isTypeInventory
                            ? _vm.creatingTimeInventoryFrequencyList
                            : _vm.creatingTimeSpotCheckFrequencyList,
                        },
                        on: { input: _vm.changedPeriodType },
                        model: {
                          value: _vm.form.scheduledJobs[0].periodType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.scheduledJobs[0],
                              "periodType",
                              $$v
                            )
                          },
                          expression: "form.scheduledJobs[0].periodType",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isTypeInventory ||
              _vm.isTypeSpotCheck ||
              _vm.isTypeTransferRequest ||
              _vm.isTypeOrderRequest
                ? _c("div", { staticClass: "Form-item creating" }, [
                    _vm.frequencyMonthly || _vm.frequencyOneTime
                      ? _c(
                          "div",
                          { staticClass: "form-item-select creating-date" },
                          [
                            _c(
                              "label",
                              { staticClass: "creating-date-label" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "ScheduledJob.detail_Popup_Label_Day"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm.frequencyMonthly
                              ? _c(
                                  "div",
                                  { staticClass: "creating-date-date" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.form.scheduledJobs[0]
                                              .dayOfMonth,
                                          expression:
                                            "form.scheduledJobs[0].dayOfMonth",
                                          modifiers: { number: true },
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            "required|numeric|between:1,31",
                                          expression:
                                            "'required|numeric|between:1,31'",
                                        },
                                      ],
                                      staticClass: "no-spinners txt",
                                      class: {
                                        "is-danger": _vm.veeErrors.has(
                                          "inventory_create_day"
                                        ),
                                      },
                                      attrs: {
                                        id: "schedule-form-creating-day",
                                        name: "inventory_create_day",
                                        "data-vv-as": _vm.$t(
                                          "ScheduledJob.detail_Popup_Label_Day"
                                        ),
                                        type: "number",
                                      },
                                      domProps: {
                                        value:
                                          _vm.form.scheduledJobs[0].dayOfMonth,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form.scheduledJobs[0],
                                            "dayOfMonth",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.veeErrors.has(
                                              "inventory_create_day"
                                            ),
                                            expression:
                                              "veeErrors.has('inventory_create_day')",
                                          },
                                        ],
                                        staticClass: "Form-item-help is-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.veeErrors.first(
                                              "inventory_create_day"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.frequencyOneTime
                              ? _c(
                                  "div",
                                  { staticClass: "creating-date-date" },
                                  [
                                    _c("CustomDatepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      class: {
                                        "is-danger":
                                          _vm.veeErrors.has(`RequestDate`),
                                      },
                                      attrs: {
                                        id: "schedule-one-time-date",
                                        name: "oneTimeDate",
                                        "data-vv-as": _vm.$t(
                                          "Request.form_RequestDate"
                                        ),
                                        pickerType: "manuel",
                                        inputIconRight: "icon-datepicker-big",
                                        inputIcon: "",
                                        position: _vm.isEdit ? "right" : "top",
                                        disabledStartDate:
                                          _vm.disabledBeforeToday,
                                      },
                                      on: { change: _vm.changedOneTimeDate },
                                      model: {
                                        value: _vm.form.scheduledJobs[0].date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.scheduledJobs[0],
                                            "date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.scheduledJobs[0].date",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    (_vm.isTypeInventory ||
                      _vm.isTypeSpotCheck ||
                      _vm.isTypeTransferRequest ||
                      _vm.isTypeOrderRequest) &&
                    _vm.frequencyWeekly
                      ? _c(
                          "div",
                          { staticClass: "form-item-select creating-date" },
                          [
                            _c(
                              "label",
                              { staticClass: "creating-date-label" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm.frequencyWeekly
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.form.scheduledJobs[0].dayOfWeek,
                                        expression:
                                          "form.scheduledJobs[0].dayOfWeek",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|is_not:-1",
                                        expression: "'required|is_not:-1'",
                                      },
                                    ],
                                    staticClass: "select m-top-10",
                                    class: {
                                      "is-danger":
                                        _vm.veeErrors.has("create_day_of_week"),
                                    },
                                    attrs: {
                                      id: "select-schedule-weekly",
                                      "data-vv-as": _vm.$t(
                                        "ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day"
                                      ),
                                      name: "create_day_of_week",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.form.scheduledJobs[0],
                                          "dayOfWeek",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "-1",
                                          id: "option-weekly-schedule-creating-not-selected",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "ScheduledJob.detail_Popup_FormField_Not_Selected"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(_vm.days, function (day) {
                                      return _c(
                                        "option",
                                        {
                                          key: day.name,
                                          attrs: {
                                            id: `option-weekly-schedule-${day.name}`,
                                          },
                                          domProps: { value: day.value },
                                        },
                                        [_vm._v(_vm._s(day.name))]
                                      )
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("create_day_of_week"),
                      expression: "veeErrors.has('create_day_of_week')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("create_day_of_week")))]
              ),
              _vm.isTypeInventory ||
              _vm.isTypeSpotCheck ||
              _vm.isTypeTransferRequest ||
              _vm.isTypeOrderRequest
                ? _c("div", { staticClass: "control creating-time" }, [
                    _c("div", { staticClass: "creating-time-time" }, [
                      _c("div", { staticClass: "creating-time-hour" }, [
                        _c("label", { staticClass: "creating-time-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ScheduledJob.detail_Popup_Label_Time_Hour"
                              )
                            )
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.form.scheduledJobs[0].hour,
                              expression: "form.scheduledJobs[0].hour",
                              modifiers: { number: true },
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric|between:0,23",
                              expression: "'required|numeric|between:0,23'",
                            },
                          ],
                          staticClass: "txt no-spinners",
                          class: {
                            "is-danger": _vm.veeErrors.has(
                              "inventory_create_hour"
                            ),
                          },
                          attrs: {
                            id: "input-schedule-job-inventory-create-hour",
                            name: "inventory_create_hour",
                            autocomplete: "off",
                            "data-vv-as": _vm.$t(
                              "ScheduledJob.detail_Popup_Label_Time_Hour"
                            ),
                            type: "number",
                          },
                          domProps: { value: _vm.form.scheduledJobs[0].hour },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form.scheduledJobs[0],
                                "hour",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "inventory_create_hour"
                                ),
                                expression:
                                  "veeErrors.has('inventory_create_hour')",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.veeErrors.first("inventory_create_hour")
                              )
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "creating-time-minute" }, [
                        _c("label", { staticClass: "creating-time-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ScheduledJob.detail_Popup_Label_Time_Minute"
                              )
                            )
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.form.scheduledJobs[0].minute,
                              expression: "form.scheduledJobs[0].minute",
                              modifiers: { number: true },
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric|between:0,59",
                              expression: "'required|numeric|between:0,59'",
                            },
                          ],
                          staticClass: "txt no-spinners",
                          class: {
                            "is-danger": _vm.veeErrors.has(
                              "inventory_create_minute"
                            ),
                          },
                          attrs: {
                            id: "input-schedule-job-inventory-create-minute",
                            name: "inventory_create_minute",
                            autocomplete: "off",
                            "data-vv-as": _vm.$t(
                              "ScheduledJob.detail_Popup_Label_Time_Minute"
                            ),
                            step: "any",
                            type: "number",
                          },
                          domProps: { value: _vm.form.scheduledJobs[0].minute },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form.scheduledJobs[0],
                                "minute",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "inventory_create_minute"
                                ),
                                expression:
                                  "veeErrors.has('inventory_create_minute')",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.veeErrors.first("inventory_create_minute")
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.isTypeTransferRequest || _vm.isTypeOrderRequest
                ? _c("div", { staticClass: "Form-item Form-item-separator" }, [
                    _c(
                      "div",
                      { staticClass: "m-top-20 m-bottom-20 checkbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            checked: this.isFinalize,
                            id: "checkbox-schedule-toggle-finalize",
                            label: _vm.$t(
                              "ScheduledJob.detail_Popup_FormField_Finalize_Checkbox"
                            ),
                          },
                          on: { change: _vm.changedFinalizeCheckbox },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTypeInventory || _vm.isTypeSpotCheck
                ? _c("div", { staticClass: "Form-item Form-item-separator" }, [
                    _c(
                      "div",
                      { staticClass: "m-top-20 m-bottom-20 checkbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            checked: this.form.scheduledJobs[1].isActive,
                            id: "checkbox-schedule-toggle-closing",
                            label: _vm.$t(
                              "ScheduledJob.detail_Popup_FormField_Close_Checkbox"
                            ),
                          },
                          on: { change: _vm.changedClosedCheckbox },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              (_vm.isTypeInventory || _vm.isTypeSpotCheck) &&
              _vm.isCheckedClosedCheckbox
                ? _c("div", { staticClass: "Form-item closing" }, [
                    !_vm.frequencyDaily
                      ? _c(
                          "div",
                          { staticClass: "form-item-select closing-date" },
                          [
                            !_vm.frequencyMonthly &&
                            !_vm.frequencyLastDay &&
                            !_vm.frequencyWeekly
                              ? _c(
                                  "label",
                                  { staticClass: "closing-date-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "ScheduledJob.detail_Popup_Label_Day"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.frequencyMonthly || _vm.frequencyLastDay
                              ? _c(
                                  "label",
                                  { staticClass: "closing-date-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "ScheduledJob.detail_Popup_After_Close_Label_Day"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.frequencyWeekly
                              ? _c(
                                  "label",
                                  { staticClass: "closing-date-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.frequencyMonthly || _vm.frequencyLastDay
                              ? _c(
                                  "div",
                                  { staticClass: "closing-date-date" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.form.scheduledJobs[1]
                                              .relativeDays,
                                          expression:
                                            "form.scheduledJobs[1].relativeDays",
                                          modifiers: { number: true },
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            "required|numeric|between:0,28",
                                          expression:
                                            "'required|numeric|between:0,28'",
                                        },
                                      ],
                                      staticClass: "no-spinners txt",
                                      class: {
                                        "is-danger": _vm.veeErrors.has(
                                          "inventory_close_day"
                                        ),
                                      },
                                      attrs: {
                                        id: "schedule-form-closing-day",
                                        name: "inventory_close_day",
                                        "data-vv-as": _vm.$t(
                                          "ScheduledJob.detail_Popup_Label_Day"
                                        ),
                                        type: "number",
                                      },
                                      domProps: {
                                        value:
                                          _vm.form.scheduledJobs[1]
                                            .relativeDays,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form.scheduledJobs[1],
                                            "relativeDays",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.veeErrors.has(
                                              "inventory_close_day"
                                            ),
                                            expression:
                                              "veeErrors.has('inventory_close_day')",
                                          },
                                        ],
                                        staticClass: "Form-item-help is-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.veeErrors.first(
                                              "inventory_close_day"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.frequencyOneTime
                              ? _c(
                                  "div",
                                  { staticClass: "closing-date-date" },
                                  [
                                    _c("CustomDatepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "datepicker",
                                      class: {
                                        "is-danger":
                                          _vm.veeErrors.has("endDate"),
                                      },
                                      attrs: {
                                        id: "schedule-one-time-close-date",
                                        name: "oneTimeDate",
                                        pickerType: "manuel",
                                        inputIconRight: "icon-datepicker-big",
                                        inputIcon: "",
                                        disabledStartDate: {
                                          to: _vm.form.scheduledJobs[0].date,
                                        },
                                      },
                                      model: {
                                        value: _vm.form.scheduledJobs[1].date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.scheduledJobs[1],
                                            "date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.scheduledJobs[1].date",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              !_vm.isTypeTransferRequest &&
              !_vm.isTypeOrderRequest &&
              _vm.frequencyWeekly &&
              _vm.isCheckedClosedCheckbox
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.scheduledJobs[1].dayOfWeek,
                          expression: "form.scheduledJobs[1].dayOfWeek",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      staticClass: "select m-top-10",
                      class: {
                        "is-danger": _vm.veeErrors.has("close_day_of_week"),
                      },
                      attrs: {
                        id: "select-schedule-weekly",
                        name: "close_day_of_week",
                        "data-vv-as": _vm.$t(
                          "ScheduledJob.detail_Popup_After_Close_Label_Weekly_Day"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form.scheduledJobs[1],
                            "dayOfWeek",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "-1",
                            id: "option-weekly-schedule-not-selected",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ScheduledJob.detail_Popup_FormField_Not_Selected"
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._l(_vm.days, function (day) {
                        return _c(
                          "option",
                          {
                            key: day.name,
                            attrs: { id: `option-weekly-schedule-${day.name}` },
                            domProps: { value: day.value },
                          },
                          [_vm._v(_vm._s(day.name))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("close_day_of_week"),
                      expression: "veeErrors.has('close_day_of_week')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("close_day_of_week")))]
              ),
              (_vm.isTypeInventory || _vm.isTypeSpotCheck) &&
              _vm.isCheckedClosedCheckbox
                ? _c("div", { staticClass: "control closing-time" }, [
                    _c("div", { staticClass: "closing-time-time" }, [
                      _c("div", { staticClass: "closing-time-hour" }, [
                        _c("label", { staticClass: "closing-time-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ScheduledJob.detail_Popup_Label_Time_Hour"
                              )
                            )
                          ),
                        ]),
                        _vm.frequencyDaily
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value:
                                    _vm.form.scheduledJobs[1].RelativeHours,
                                  expression:
                                    "form.scheduledJobs[1].RelativeHours",
                                  modifiers: { number: true },
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|numeric|between:0,23",
                                  expression: "'required|numeric|between:0,23'",
                                },
                              ],
                              staticClass: "txt no-spinners",
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  "inventory_close_hour"
                                ),
                              },
                              attrs: {
                                id: "input-schedule-job-inventory-close-hour-freq-daily",
                                name: "inventory_close_hour",
                                autocomplete: "off",
                                "data-vv-as": _vm.$t(
                                  "ScheduledJob.detail_Popup_Label_Time_Hour"
                                ),
                                type: "number",
                              },
                              domProps: {
                                value: _vm.form.scheduledJobs[1].RelativeHours,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form.scheduledJobs[1],
                                    "RelativeHours",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.form.scheduledJobs[1].hour,
                                  expression: "form.scheduledJobs[1].hour",
                                  modifiers: { number: true },
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|numeric|between:0,23",
                                  expression: "'required|numeric|between:0,23'",
                                },
                              ],
                              staticClass: "txt no-spinners",
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  "inventory_close_hour"
                                ),
                              },
                              attrs: {
                                id: "input-schedule-job-inventory-close-hour",
                                name: "inventory_close_hour",
                                autocomplete: "off",
                                "data-vv-as": _vm.$t(
                                  "ScheduledJob.detail_Popup_Label_Time_Hour"
                                ),
                                type: "number",
                              },
                              domProps: {
                                value: _vm.form.scheduledJobs[1].hour,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form.scheduledJobs[1],
                                    "hour",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "inventory_close_hour"
                                ),
                                expression:
                                  "veeErrors.has('inventory_close_hour')",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.veeErrors.first("inventory_close_hour")
                              )
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "closing-time-minute" }, [
                        _c("label", { staticClass: "closing-time-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ScheduledJob.detail_Popup_Label_Time_Minute"
                              )
                            )
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.form.scheduledJobs[1].minute,
                              expression: "form.scheduledJobs[1].minute",
                              modifiers: { number: true },
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric|between:0,59",
                              expression: "'required|numeric|between:0,59'",
                            },
                          ],
                          staticClass: "txt no-spinners",
                          class: {
                            "is-danger": _vm.veeErrors.has(
                              "inventory_close_minute"
                            ),
                          },
                          attrs: {
                            id: "input-schedule-job-inventory-close-minute",
                            name: "inventory_close_minute",
                            autocomplete: "off",
                            "data-vv-as": _vm.$t(
                              "ScheduledJob.detail_Popup_Label_Time_Minute"
                            ),
                            step: "any",
                            type: "number",
                          },
                          domProps: { value: _vm.form.scheduledJobs[1].minute },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form.scheduledJobs[1],
                                "minute",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "inventory_close_minute"
                                ),
                                expression:
                                  "veeErrors.has('inventory_close_minute')",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.veeErrors.first("inventory_close_minute")
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                id: "btn-schedule-job-submit",
                variant: "full",
                success: "",
                disabled: _vm.isLoadingCreate,
                type: "submit",
                form: "form-scheduled-job",
                isLoading: _vm.isLoadingCreate,
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("ScheduledJob.detail_Popup_Button_Submit"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }