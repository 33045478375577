import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    detailPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    detailPage: state => ({
      ...state.detailPage,
      last: Math.ceil(state.detailPage.total / state.detailPage.size)
    })
  },
  actions: {
    async createScheduleJob({ commit }, params) {
      const results = await requestHandler({
        title: 'createScheduleJob',
        methodName: 'createScheduleJob',
        method: () => {
          return HTTP.post('Schedule', params)
        },
        success: result => result
      })
      return results
    },
    async getSchedule({ commit }, params) {
      const results = await requestHandler({
        title: 'getSchedule',
        methodName: 'getSchedule',
        method: () => {
          return HTTP.get('Schedule', {params})
        },
        success: result => result
      })
      return results
    },

    async activationSchedule({ commit }, params) {
      const results = await requestHandler({
        title: 'activationSchedule',
        methodName: 'activationSchedule',
        method: () => {
          return HTTP.put('Schedule/activation', params)
        },
        success: result => result
      })
      return results
    },

    async dismissError({ commit }, params) {
      const results = await requestHandler({
        title: 'dismissError',
        methodName: 'dismissError',
        method: () => {
          return HTTP.put('Schedule/history/dismisserror', params)
        },
        success: result => result
      })
      return results
    },

    async getAllSchedule({ commit, dispatch, state }, params = {}) {
      const PageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.Page.number })
      const payload = {
        ...params,
        pageNumber: PageNumber
      }
      const loadingName = 'getAllSchedule'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Schedule/all', payload)
        },
        success: result => {
          commit('SET_PAGINATION', result.data)
          return result
        }
      })
      return results
    },

    async getScheduleDetail({ commit, dispatch, state }, params) {
      const payload = { ...params }
      if (!params.pageSize) {
        payload.pageSize = 99999
        payload.pageNumber = 1
      } else {
        const PageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.detailPage.number })
        payload.pageNumber = PageNumber
        payload.pageSize = params.pageSize
      }
      const loadingName = 'getScheduleDetail'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Schedule/history/all', payload)
        },
        success: result => {
          commit('SET_DETAIL_PAGINATION', result.data)
          return result
        }
      })
      return results
    }
  },
  mutations: {
    SET_PAGINATION(state, data) {
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    SET_DETAIL_PAGINATION(state, data) {
      state.detailPage.number = data.pageNumber
      if (data.pageSize !== 99999) state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
